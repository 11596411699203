import React from "react"
import { Link } from "gatsby"
import { LazyLoadImage } from "react-lazy-load-image-component"
import facebook from "../../images/facebook.png"
import linkedin from "../../images/linkedin.png"
// import Modal from "../../components/modal"

import ani from "../../images/ani.png"

import acc from "../../images/acc.jpg"
import pcc from "../../images/pcc.jpg"
import mcc from "../../images/mcc.jpg"
import icf from "../../images/icf.png"

const footer = props => {
  return (
    <div>
      <div id="footer">
        <section id="top-footer">
          <div className="container">
            <div className="row">
              <div className="col-md-5 mb-5">
                <div className="left-content">
                  <h5 className="footer-title mb-4">AGL</h5>
                  <p className="footer-p">
                    We want to live in a world where people wake up and say, "I
                    trust my boss, I love my team, and I'm passionate about my
                    work." We're here to make that happen.
                  </p>
                  <a
                    className="fmail d-block mb-2"
                    href="mailto:impact@coachingforgood.org"
                  >
                    impact@coachingforgood.org
                  </a>
                  <a className="fphone d-block mb-3" href="tel:7198002149">
                    719-800-2149
                  </a>
                  <div className="social-media align-content-center justify-content-between">
                    <a
                      className="facebook mr-2"
                      href="https://www.facebook.com/coachingforgood.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LazyLoadImage
                        className="img-fluid"
                        src={facebook}
                        alt="Facebook Icon"
                      />
                    </a>
                    <a
                      className="linkedin"
                      href="https://www.linkedin.com/company/18449628"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LazyLoadImage
                        className="img-fluid"
                        src={linkedin}
                        alt="LinkedIn Icon"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-3 mb-5">
                <div className="center-content">
                  <h5 className="footer-title mb-4">QUICK LINKS</h5>
                  <ul className="f-menu p-0">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/about-us">About</Link>
                    </li>
                    <li>
                      <Link to="/blog">Blog</Link>
                    </li>
                    {/* <li>
                      <Link to="/testimonials">Testimonials</Link>
                    </li> */}
                    {/* <li>
                      <Link to="/resources">Resources</Link>
                    </li> */}
                    <li>
                      <Link to="/contact-us">Schedule</Link>
                    </li>
                    <li>
                      <Link to="/newsletter">Newsletter</Link>
                    </li>
                    <li>
                      <Link to="/virtual-all-staff">Virtual All Staff</Link>
                    </li>
                    <li>
                      <a
                        href="https://coachingforgood.org/sitemap.xml"
                        target="_blank"
                      >
                        Sitemap
                      </a>
                    </li>
                    <li>
                      <Link to="/html-sitemap">HTML Sitemap</Link>
                    </li>
                    <li>
                      <Link to="/resource-sitemap">Resource Sitemap</Link>
                    </li>
                    {/*<li>
                      <Modal>
                        <Link to="">Schedule</Link>
                      </Modal>
                    </li>*/}
                  </ul>
                </div>
              </div>
              <div className="col-md-4">
                <div className="right-content">
                  {/*<div class="address-map">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3106.312437084087!2d-104.75738768465044!3d38.87109297957471!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x871345fef4fddd8f%3A0x3fcabb5734544f5b!2s2621%20Hearthwood%20Ln%2C%20Colorado%20Springs%2C%20CO%2080917%2C%20USA!5e0!3m2!1sen!2sph!4v1580461749686!5m2!1sen!2sph"
                      width="100%"
                      height="280"
                      frameborder="0"
                      style={{ border: "0" }}
                      allowfullscreen=""
                      title="Address"
                    />
                  </div>*/}
                  <a
                    href="https://www.bbb.org/us/co/colorado-springs/profile/business-coach/apex-generation-leadership-0785-87383136/#sealclick"
                    target="_blank"
                    rel="nofollow"
                  >
                    <img
                      src="https://seal-southerncolorado.bbb.org/seals/darkgray-seal-200-130-bbb-87383136.png"
                      alt="Apex Generation Leadership BBB Business Review"
                    />
                  </a>
                  <div className="row mt-3">
                    <div className="col-md-3 mb-3">
                      <a
                        className="facebook mr-2"
                        href="https://coachingfederation.org/credentials-and-standards/acc-paths"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <LazyLoadImage
                          className="img-fluid text-center"
                          src={acc}
                          alt="Facebook Icon"
                        />
                      </a>
                    </div>
                    <div className="col-md-3 mb-3">
                      <a
                        className="facebook mr-2"
                        href="https://coachingfederation.org/credentials-and-standards/pcc-paths"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <LazyLoadImage
                          className="img-fluid text-center"
                          src={pcc}
                          alt="Facebook Icon"
                        />
                      </a>
                    </div>

                    <div className="col-md-3 mb-3">
                      <a
                        className="facebook mr-2"
                        href="https://coachingfederation.org/credentials-and-standards/mcc-path"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <LazyLoadImage
                          className="img-fluid text-center"
                          src={mcc}
                          alt="Facebook Icon"
                        />
                      </a>
                    </div>
                    <div className="col-md-3 mb-3">
                      <a
                        className="facebook mr-2"
                        href="https://coachingfederation.org"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <LazyLoadImage
                          className="img-fluid text-center"
                          src={icf}
                          alt="Facebook Icon"
                        />
                      </a>
                    </div>
                  </div>

                  <div className="social-media align-content-center justify-content-between mt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <a
                          className="mr-2 text-center"
                          href="https://appliedneuroscienceinstitute.com/product/coach-training-diploma/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <LazyLoadImage
                            className="img-fluid text-center"
                            src={ani}
                            alt="Facebook Icon"
                          />
                        </a>
                      </div>
                      <div className="col-md-8 mx-auto">
                        <p className="footer-p mt-1">
                          Licensed NeuroPositive Coach
                          <br />
                          Applied Neuroscience Institute
                          <br />
                          August 9, 2018
                        </p>
                      </div>
                    </div>
                  </div>

                  {/*<a className="linkedin" href="/">
                      <LazyLoadImage
                        className="img-fluid"
                        src={linkedin}
                        alt="LinkedIn Icon"
                      />
                    </a>*/}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="copyright">
          <div className="container">
            <div className="d-blockt text-center text-md-left d-md-flex justify-content-between align-items-center">
              <p className="copyright mb-0 text-white">
                &copy; Copyright {new Date().getFullYear()} - AGL. All rights
                reserved.
              </p>
              <p className="copyright mb-0 text-white">
                Designed &amp; powered by 
                <a
                  href="https://webriq.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span style={{ color: "#fbb040" }}>WebriQ</span>
                </a>
                <span>.</span>
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default footer
