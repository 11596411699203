import React from "react"
import { Link } from "gatsby"
import SideBarMenu from "../sidebar/sidebar"
import Sticky from "react-sticky-el"
import styl from "./header.module.css"
import logo from "../../images/agl-new-logo-v3.png"
// import Modal from "../modal"

const Header = () => (
  <header>
    <Sticky
      className={styl.stickyWrapper}
      stickyClassName={styl.isSticky}
      stickyStyle={{ transform: "unset", zIndex: "9" }}
    >
      <div className={`header ${styl.header}`}>
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-4">
              <div className="header-logo">
                <Link to="/">
                  <img className="logo-img" src={logo} alt="Logo" />
                </Link>
              </div>
            </div>
            <div className="col-8 text-right">
              <div className="d-inline-flex">
                <div className="modal-top mt-3">
                  <a href="tel:7198002149">
                    <span className="schedule-btn top-btn-sched mr-3 sched-modal">
                      <i class="fa fa-phone" aria-hidden="true"></i>
                      &nbsp;719-800-2149
                    </span>
                  </a>
                </div>
                <div className="modal-top mt-3">
                  <Link to="/contact-us">
                    <span className="schedule-btn top-btn-sched mr-3 sched-modal">
                      <i class="fa fa-envelope-o" aria-hidden="true"></i>
                      &nbsp;Contact Us
                    </span>
                  </Link>
                </div>
                <SideBarMenu />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Sticky>
  </header>
)

export default Header
