import React from "react"
import { Link } from "gatsby"
import { Nav } from "react-bootstrap"
import "./menu.css"
// import Modal from "../modal"

export default () => (
  <div className="navigation">
    <Nav className="flex-column">
      <Nav.Item>
        <Link to="/">Home</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/about-us">About</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/blog">Blog</Link>
      </Nav.Item>
      {/* <Nav.Item>
        <Link to="/testimonials">Testimonials</Link>
      </Nav.Item> */}
      {/* <Nav.Item>
        <Link to="/resources">Resources</Link>
      </Nav.Item> */}
      <Nav.Item>
        <Link to="/contact-us">Schedule</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/newsletter">Newsletter</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/virtual-all-staff">Virtual All Staff</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/360review">360 Review</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/culture-pulse">Culture Pulse</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/leadervest">LeaderVest</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/healthcare-leadership-development-courses">Healthcare Leadership Development Courses</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/one-on-one-coaching">One On One Coaching</Link>
      </Nav.Item>
      {/*<Nav.Item>
        <Modal>
          <Link to="" className="float-left text-white">
            Schedule
          </Link>
        </Modal>
      </Nav.Item>*/}
    </Nav>
  </div>
)
